import { useCallback, useEffect, useMemo } from "react";
import type { SubmitOptions } from "@remix-run/react";
import {
  useActionData,
  useFormAction,
  useNavigation,
  useSubmit,
} from "@remix-run/react";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isObjectEmpty(obj: object): boolean {
  return Object.keys(obj).length === 0;
}

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get("X-Forwarded-Host") ??
    request.headers.get("host") ??
    new URL(request.url).host;
  const protocol = host.includes("localhost") ? "http" : "https";
  return `${protocol}://${host}`;
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit["headers"] | null | undefined>
) {
  const combined = new Headers();
  for (const header of headers) {
    if (!header) continue;
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value);
    }
  }
  return combined;
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
  formAction,
  formMethod = "POST",
  state = "non-idle",
}: {
  formAction?: string;
  formMethod?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
  state?: "submitting" | "loading" | "non-idle";
} = {}) {
  const contextualFormAction = useFormAction();
  const navigation = useNavigation();
  const isPendingState =
    state === "non-idle"
      ? navigation.state !== "idle"
      : navigation.state === state;
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  );
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
  ...responseInits: Array<ResponseInit | null | undefined>
) {
  let combined: ResponseInit = {};
  for (const responseInit of responseInits) {
    combined = {
      ...responseInit,
      headers: combineHeaders(combined.headers, responseInit?.headers),
    };
  }
  return combined;
}

declare type SubmitTarget =
  | HTMLFormElement
  | HTMLButtonElement
  | HTMLInputElement
  | FormData
  | URLSearchParams
  | {
      [name: string]: string;
    }
  | null;

export function useSubmitPromise() {
  const submit = useSubmit();
  const navigation = useNavigation();
  const actionData = useActionData();
  const $deferred = useMemo(() => deferred(), []);

  useEffect(() => {
    if (navigation.state === "idle" && actionData) {
      $deferred.resolve(actionData);
    }
  }, [$deferred, navigation.state, actionData]);

  const _submit = useCallback(
    (target: SubmitTarget, options: SubmitOptions = {}) => {
      submit(target, options);
      return $deferred.promise;
    },
    [$deferred.promise, submit]
  );

  return _submit;
}

// create a *deferred* promise
function deferred() {
  let resolve: any;
  let reject: any;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return { resolve, reject, promise };
}

type ConditionalFunction = (a: any, b?: any, c?: any) => boolean;

export const conditionals: { [key: string]: ConditionalFunction } = {
  equals: (a, b) => a == b,
  notEquals: (a, b) => a != b,
  greaterThan: (a, b) => a > b,
  greaterThanOrEqual: (a, b) => a >= b,
  lessThan: (a, b) => a < b,
  lessThanOrEqual: (a, b) => a <= b,
  and: (a, b) => !!a && !!b,
  or: (a, b) => !!a || !!b,
  not: (a) => !a,
  contains: (a: string, b: string) => a.includes(b),
  startsWith: (a: string, b: string) => a.startsWith(b),
  endsWith: (a: string, b: string) => a.endsWith(b),
  arrayContains: (arr: any[], elem: any) => arr.includes(elem),
  arrayLengthEqual: (arr: any[], len: number) => arr.length === len,
  keyExists: (obj: Record<string, any>, key: string) => key in obj,
  keyValueEqual: (obj: Record<string, any>, key: string, value: any) =>
    obj[key] === value,
};

interface PhoneNumberParts {
  phoneCountryCode: string;
  phoneNumber: string | undefined;
}

export function parsePhoneNumber(phone: string): PhoneNumberParts {
  if (!phone) {
    return {
      phoneCountryCode: "+55",
      phoneNumber: undefined,
    };
  }

  // Remove all non-digit characters except the leading '+'
  const cleanedPhone = phone.replace(/^(\+)|\D/g, "$1");

  let phoneCountryCode = "+55"; // Default country code
  let phoneNumber: string;

  // Function to determine country code length
  const getCountryCodeLength = (number: string): number => {
    if (number.startsWith("+55")) return 3;
    if (number.startsWith("+1")) return 2;
    // Add more country codes here if needed
    return number.startsWith("+") ? 3 : 0; // Default to 3 for other international numbers
  };

  const countryCodeLength = getCountryCodeLength(cleanedPhone);

  if (countryCodeLength > 0) {
    phoneCountryCode = cleanedPhone.slice(0, countryCodeLength);
    phoneNumber = cleanedPhone.slice(countryCodeLength);
  } else if (cleanedPhone.length === 10 || cleanedPhone.length === 11) {
    // Assume it's a Brazilian number without country code
    phoneNumber = cleanedPhone;
  } else {
    throw new Error("Invalid phone number format");
  }

  // Validate number length based on country code
  if (
    phoneCountryCode === "+55" &&
    (phoneNumber.length < 10 || phoneNumber.length > 11)
  ) {
    throw new Error("Invalid Brazilian phone number length");
  } else if (phoneCountryCode === "+1" && phoneNumber.length !== 10) {
    throw new Error("Invalid US phone number length");
  }

  return {
    phoneCountryCode,
    phoneNumber,
  };
}

export function countVariables(components: any) {
  if (!components) return 0;

  const variablePattern = /{{\d+}}/g;
  const variableSet = [];

  components.forEach((component: any) => {
    const text = component.text;
    if (text) {
      const variables = text.match(variablePattern);
      if (variables) {
        variables.forEach((variable: any) => variableSet.push(variable));
      }
    }
  });

  return variableSet.length;
}
